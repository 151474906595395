<template>
	<div class="circularStrategy">
		<!-- <title-two url="/newHome" title="交易详情" /> -->
		<!-- <common-head :title="$t('tba.MartinDetails')" :is-return="true"></common-head> -->
		<common-head :url="homepath" :title="robot_title" :is-return="true" @onclickRight="onclickRight">
			<span class="text" slot="text">{{ $t('tba.TransactionRecord') }}</span>
		</common-head>

		<div class="layoutBox bgGray">
			<div class="err_msg" v-if="dataInfo.error != ''">{{ dataInfo.error }}</div>
			<div class="msg_list">
				<div class="contWhiteBox content circular">
					<div class="csTitle" v-if="dataInfo.type == 'wave_f'">
						{{ dataInfo.currency_pair }}
						<span class="gray1" v-if="strategy.v8 == 2">{{ $t('tba.zuokong') }}</span>
						<span class="gray1" v-if="strategy.v8 == 2">x{{ strategy.v7 }}</span>
						<span class="gray2" v-if="strategy.v8 == 1">{{ $t('tba.zuoduo') }}</span>
						<span class="gray2" v-if="strategy.v8 == 1">x{{ strategy.v7 }}</span>
					</div>
					<div class="csTitle" v-if="dataInfo.type == 'wave_f_long'">
						{{ dataInfo.currency_pair }}
						<!-- 					<span class="gray1" v-if="strategy.v8 == 2">{{ $t('tba.zuokong') }}</span>
									<span class="gray1" v-if="strategy.v8 == 2">x{{strategy.v7}}</span> -->
						<span class="gray2">{{ $t('tba.zuoduo') }}</span>
						<span class="gray2">x{{ strategy.v7 }}</span>
					</div>
					<div class="csTitle" v-if="dataInfo.type == 'wave_f_short'">
						{{ dataInfo.currency_pair }}
						<span class="gray1">{{ $t('tba.zuokong') }}</span>
						<span class="gray1">x{{ strategy.v7 }}</span>
					</div>
					<div class="csTitle" v-if="dataInfo.type == 'manual_f_long'">
						{{ dataInfo.currency_pair }}
						<span class="gray2">{{ $t('tba.zuoduo') }}</span>
						<span class="gray2">x{{ strategy.v1 }}</span>
					</div>
					<div class="csTitle" v-if="dataInfo.type == 'manual_f_short'">
						{{ dataInfo.currency_pair }}
						<span class="gray1">{{ $t('tba.zuokong') }}</span>
						<span class="gray1">x{{ strategy.v1 }}</span>
					</div>
					<div class="csTitle" v-if="dataInfo.type == 'wave'">
						{{ dataInfo.currency_pair }}
					</div>
					<div class="csTitle" v-else-if="dataInfo.type == 'wangge_f'">
						{{ dataInfo.currency_pair }}
						<span class="gray">{{ $t('tba.' + dataInfo.strategy_en) }}</span>
						<span class="gray1" v-if="strategy.v10 == 2">{{ $t('tba.zuokong') }}</span>
						<span class="gray1" v-if="strategy.v10 == 2">x{{ strategy.v9 }}</span>
						<span class="gray2" v-if="strategy.v10 == 1">{{ $t('tba.zuoduo') }}</span>
						<span class="gray2" v-if="strategy.v10 == 1">x{{ strategy.v9 }}</span>
					</div>
					<div class="csTitle" v-if="dataInfo.type == 'wave_f_coin'">
						{{ dataInfo.currency_pair }}
						<span class="gray1" v-if="strategy.v8 == 2">{{ $t('tba.zuokong') }}</span>
						<span class="gray1" v-if="strategy.v8 == 2">x{{ strategy.v7 }}</span>
						<span class="gray2" v-if="strategy.v8 == 1">{{ $t('tba.zuoduo') }}</span>
						<span class="gray2" v-if="strategy.v8 == 1">x{{ strategy.v7 }}</span>
					</div>
					<div class="csTitle" v-else-if="dataInfo.type == 'wangge' || dataInfo.type == 'wangge2' || dataInfo.type == 'wangge_m' || dataInfo.type == 'wangge_x'">
						{{ dataInfo.currency_pair }}
						<span class="gray">{{ $t('tba.' + dataInfo.strategy_en) }}</span>
					</div>

					<div class="head_list">
						<div class="numItem">
							<p>{{ $t('tba.PositionAmount') }}（{{ dataInfo.currency_pair | danwei }}）</p>
							<span>{{ dataInfo.num_ }}</span>
						</div>
						<div class="numItem">
							<p>{{ $t('tba.AveragePositionPrice') }}</p>
							<span>{{ dataInfo.avg_price | danwei3 }}</span>
						</div>
						<div class="numItem">
							<p>{{ $t('tba.Numberofpositionsfilled') }}</p>
							<span>{{ dataInfo.times }}</span>
						</div>
					</div>
					<div class="head_list">
						<div class="numItem1" v-if="fangshi == 1">
							<p>{{ $t('tba.PositionQuantity') }}（{{ dataInfo.token }}）</p>
							<span>{{ dataInfo.num }}</span>
						</div>
						<div class="numItem1" v-if="fangshi == 2">
							<p v-if="dataInfo.type !== 'wave_f_coin'">{{ $t('tba.PositionQuantity') }}（{{ $t('tba.Contract') }}）</p>
							<p v-else>{{ $t('tba.PositionQuantity') }}（{{ dataInfo.token }}）</p>
							<span>{{ dataInfo.num }}</span>
						</div>
						<div class="numItem1">
							<p>{{ $t('tba.CurrentPrice') }}</p>
							<span>{{ dataInfo.last | danwei3 }}</span>
						</div>
						<div class="numItem1" v-if="dataInfo.type === 'wave' || dataInfo.type === 'wave_f' || dataInfo.type === 'wangge_f' || dataInfo.type === 'wave_f_long' || dataInfo.type === 'wave_f_short' || dataInfo.type === 'wave_f_coin'">
							<p>{{ $t('tba.zhiyingcishu') }}</p>
							<span>{{ dataInfo.win_times }}</span>
						</div>
						<div class="numItem1" v-else>
							<p>{{ $t('tba.Up') }}</p>
							<span>{{ (dataInfo.win_rate * 100) | ratioFormat }}%</span>
						</div>
					</div>
					<div class="csTitle1">
						{{ $t('tba.AccumulatedProfit') }}
						<span  v-if="dataInfo.type !== 'wave_f_coin'">{{ dataInfo.total_profit | danwei3 }} {{ dataInfo.currency_pair | danwei }}</span>
						<span v-else>{{ dataInfo.total_profit | danwei3 }} {{ dataInfo.token }}</span>
					</div>
				</div>

				<div class="contWhiteBox content strategy">
					<div class="csTitle">
						{{ $t('tba.StrategyRelated') }}
						<span>{{ $t('tba.' + dataInfo.type) }}</span>
					</div>
					<van-row v-if="dataInfo.type === 'wangge'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Replenishmenttimes') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitmargin') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitstopcallback') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Marginofreplenishment') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Makeupcallback') }}</span>
							<span class="span2">{{ strategy.v6 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Horizontalpushornot') }}</span>
							<span class="span2">{{ strategy.v7 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.bucangbeishu') }}</span>
							<span class="span2">{{ strategy.v8 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wangge_x'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Replenishmenttimes') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitmargin') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitstopcallback') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Marginofreplenishment') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.bucangbeishu') }}</span>
							<span class="span2">{{ strategy.v6 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Makeupcallback') }}</span>
							<span class="span2">{{ strategy.v31 | ratioFormat }}%</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wangge2'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Cyclesetting') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.DeclineRange') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Replenishmenttimes') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitmargin') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitstopcallback') }}</span>
							<span class="span2">{{ strategy.v6 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Marginofreplenishment') }}</span>
							<span class="span2">{{ strategy.v7 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Makeupcallback') }}</span>
							<span class="span2">{{ strategy.v8 | ratioFormat }}%</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Zuidabucangcishu') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OneProfitmargin') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Wanggekuandu') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Suofanglv') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Bucangjinersuofanglv') }}</span>
							<span class="span2">{{ strategy.v6 | ratioFormat }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wangge_m'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Replenishmenttimes') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitmargin') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitstopcallback') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Marginofreplenishment') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Makeupcallback') }}</span>
							<span class="span2">{{ strategy.v12 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Horizontalpushornot') }}</span>
							<span class="span2">{{ strategy.v13 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.pingtuifudu') }}</span>
							<span class="span2">{{ strategy.v14 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.bucangbeishu') }}</span>
							<span class="span2">{{ strategy.v15 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave_f'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Zuidabucangcishu') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OneProfitmargin') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Wanggekuandu') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Suofanglv') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Bucangjinersuofanglv') }}</span>
							<span class="span2">{{ strategy.v6 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.gangganbeishu') }}</span>
							<span class="span2">x{{ strategy.v7 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave_f_long'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Zuidabucangcishu') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OneProfitmargin') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Wanggekuandu') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Suofanglv') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Bucangjinersuofanglv') }}</span>
							<span class="span2">{{ strategy.v6 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.gangganbeishu') }}</span>
							<span class="span2">x{{ strategy.v7 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave_f_short'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Zuidabucangcishu') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OneProfitmargin') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Wanggekuandu') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Suofanglv') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Bucangjinersuofanglv') }}</span>
							<span class="span2">{{ strategy.v6 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.gangganbeishu') }}</span>
							<span class="span2">x{{ strategy.v7 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wangge_f'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Replenishmenttimes') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitmargin') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Profitstopcallback') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Marginofreplenishment') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Makeupcallback') }}</span>
							<span class="span2">{{ strategy.v6 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Horizontalpushornot') }}</span>
							<span class="span2">{{ strategy.v7 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.bucangbeishu') }}</span>
							<span class="span2">{{ strategy.v8 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.gangganbeishu') }}</span>
							<span class="span2">x{{ strategy.v9 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'manual_f_long'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.gangganbeishu') }}</span>
							<span class="span2">x{{ strategy.v1 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'manual_f_short'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.gangganbeishu') }}</span>
							<span class="span2">x{{ strategy.v1 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave_f_coin'">
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OpeningAmount') }}</span>
							<span class="span2">{{ strategy.v1 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Zuidabucangcishu') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.OneProfitmargin') }}</span>
							<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Wanggekuandu') }}</span>
							<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Suofanglv') }}</span>
							<span class="span2">{{ strategy.v5 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.Bucangjinersuofanglv') }}</span>
							<span class="span2">{{ strategy.v6 | ratioFormat }}</span>
						</van-col>
						<van-col span="12" class="strategyItem">
							<span class="span1">{{ $t('tba.gangganbeishu') }}</span>
							<span class="span2">x{{ strategy.v7 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wangge'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v9 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wangge_x'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v32 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wangge2'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v9 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v7 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wangge_m'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v16 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave_f'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v9 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave_f_long'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v8 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave_f_short'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v8 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wangge_f'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v11 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'wave_f_coin'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v9 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'manual_f_long'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhiyingjiage') }}</span>
							<span class="span2">{{ strategy.v3 }}</span>
						</van-col>
					</van-row>
					<van-row v-if="dataInfo.type === 'manual_f_short'">
						<div class="xian"></div>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhisunjiage') }}</span>
							<span class="span2">{{ strategy.v2 }}</span>
						</van-col>
						<van-col span="12" class="strategyItem1">
							<span class="span1">{{ $t('tba.zhiyingjiage') }}</span>
							<span class="span2">{{ strategy.v3 }}</span>
						</van-col>
					</van-row>
				</div>

				<div class="contWhiteBox content action">
					<div class="csTitle">{{ $t('tba.Policyoperation') }}</div>
					<van-row type="flex" justify="space-around">
						<van-col span="6" class="actionItem" @click="robotParamSetup">
							<img src="@/assets/imgs/icon/set.png" />
							<p>{{ $t('tba.Policysettings') }}</p>
						</van-col>
						<van-col span="6" class="actionItem" @click="show0 = true">
							<img src="@/assets/imgs/icon/maichu.png" />
							<p>{{ $t('tba.Clearingandselling') }}</p>
						</van-col>
						<van-col span="6" class="actionItem" @click="show3 = true" v-if="dataInfo.type === 'wave' || dataInfo.type === 'wave_f' || dataInfo.type === 'wave_f_long' || dataInfo.type === 'wave_f_short'">
							<img src="@/assets/imgs/icon/qingliguadan.png" />
							<p>{{ $t('tba.Qingliguadan') }}</p>
						</van-col>
						<van-col span="6" class="actionItem" @click="manualBuchang" v-if="dataInfo.type === 'wangge' || dataInfo.type === 'wangge2' || dataInfo.type === 'wangge_f' || dataInfo.type === 'manual_f_long' || dataInfo.type === 'manual_f_short' || dataInfo.type === 'wave_f_coin' || dataInfo.type === 'wangge_x'">
							<img src="@/assets/imgs/icon/bucang.png" />
							<p>{{ $t('tba.manualBuchang') }}</p>
						</van-col>
						<van-col span="6" class="actionItem" @click="manualMaichu" v-if="dataInfo.type === 'wangge' || dataInfo.type === 'wangge2' || dataInfo.type === 'wangge_f' || dataInfo.type === 'manual_f_long' || dataInfo.type === 'manual_f_short' || dataInfo.type === 'wave_f_coin' || dataInfo.type === 'wangge_x'">
							<img src="@/assets/imgs/icon/shou_mai.png" />
							<p>{{ $t('tba.manualMaichu') }}</p>
						</van-col>
					</van-row>
				</div>
				<div class="comBtnStyle" @click="toggleBot()" style="margin-top: 1rem;">
					<span>{{ returnBtntag(dataInfo.state) }}</span>
				</div>
				<!-- 一键清仓2次确认 -->
				<van-dialog v-model="show0" title="提示" show-cancel-button message="是否确认一键清仓?" @confirm="yes"></van-dialog>
				<!-- 清理挂单2次确认 -->
				<van-dialog v-model="show3" title="提示" show-cancel-button message="是否确认清理挂单?" @confirm="yes3"></van-dialog>
				<!-- 补仓对话框 -->
				<van-dialog v-model="show" show-cancel-button :title="$t('tba.oneButtonReplenishment')" @confirm="buchang">
					<div class="dialogStyle">
						<van-field :label="$t('tba.ReplenishmentQuantity')" ref="fieldNum" v-model="num" autofocus="autofocus" class="psdInput" type="number" />
					</div>
				</van-dialog>
				<!-- 补仓对话框 -->
				<van-dialog v-model="show1" show-cancel-button :title="$t('tba.manualMaichu') + '(' + $t('tba.Contract') + ')'" @confirm="maichu" v-if="fangshi == 2 && dataInfo.type !== 'wave_f_coin'">
					<div class="dialogStyle">
						<van-field :label="$t('tba.SelloutQuantity')" ref="fieldNum" v-model="num1" autofocus="autofocus" class="psdInput" type="number" @input="input_shu()" />
					</div>
				</van-dialog>
				<van-dialog v-model="show1" show-cancel-button :title="$t('tba.manualMaichu') + '(' + this.dataInfo.token + ')'" @confirm="maichu" v-if="fangshi == 2 && dataInfo.type == 'wave_f_coin'">
					<div class="dialogStyle">
						<van-field :label="$t('tba.SelloutQuantity')" ref="fieldNum" v-model="num1" autofocus="autofocus" class="psdInput" type="number" @input="input_shu()" />
					</div>
				</van-dialog>
				<van-dialog v-model="show1" show-cancel-button :title="$t('tba.manualMaichu') + '(' + this.dataInfo.token + ')'" @confirm="maichu" v-if="fangshi == 1">
					<div class="dialogStyle">
						<van-field :label="$t('tba.SelloutQuantity')" ref="fieldNum" v-model="num1" autofocus="autofocus" class="psdInput" type="number" @input="input_shu()" />
					</div>
				</van-dialog>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import commonHead from '@/components/commonHead';
export default {
	name: 'circularStrategy',
	components: {
		commonHead
	},
	data() {
		return {
			bot_id: this.$route.query.bot_id,
			homepath: this.$route.query.homepath,
			exchange: this.$route.query.exchange,
			// -------20200414[start]--------
			robot_title: this.$t('tba.title'), //标题名字
			dataInfo: {},
			// -------20200414[end]--------
			robot_id: '',
			strategy: {}, //各个参数值
			num: 10, //补仓数量
			show0: false, //一键清仓2次确认对话框
			show3: false, //清理挂单2次确认对话框
			show: false, //补仓对话框
			state: null,
			show1: false,
			num1: 0, //卖出数量
			mai_tital: '',
			fangshi: this.$route.query.fangshi
		};
	},
	filters: {
		ratioFormat(value) {
			let str = value + '';
			if (str.length > 5) {
				let f = parseFloat(value);
				return f.toFixed(2);
			} else {
				return value;
			}
		},
		danwei(v) {
			if (v) {
				let arr = v.split('/');
				return arr[1];
			}
		},
		danwei3(v) {
			// v = 0.00054611;
			let index;
			if (v != 0) {
				if (v <= 0.1) {
					if (v.toString().indexOf('.') != -1) {
						let b = v.toString().split('.')[1];
						let long = b.length > 3 ? (b[0] == 0 ? 3 : b.length) : b.length;
						for (let key in b) {
							if (b[key] != 0) {
								index = key;
								break;
							}
						}
						return Number(v).toFixed(Number(index) + Number(long));
					}else {
						return v;
					}
				} else {
					return v;
				}
			} else {
				return 0;
			}
		}
	},
	created() {
		this.getBot();
	},
	methods: {
		onclickRight() {
			this.$router.push({
				path: '/transactionRecords',
				query: {
					currency_pair: this.dataInfo.currency_pair,
					exchange: this.exchange,
					fangshi: this.fangshi
				}
			});
		},
		input_shu() {
			if (this.num1 > this.dataInfo.num) {
				this.$toast.fail(this.$t('tba.cao_num'));
				this.num1 = 0;
			}
		},
		// 返回-启动/停止-字符
		returnBtntag(state) {
			if (state == 0) {
				this.state = 1;
				return this.$t('tba.start_up');
			}
			if (state == 1) {
				this.state = 0;
				return this.$t('tba.stop_it');
			}
		},
		// 弹出补仓对话框
		manualBuchang() {
			this.show = true;
			this.$nextTick(() => {
				this.$refs.fieldNum.focus();
			});
		},
		// 弹出卖出对话框
		manualMaichu() {
			this.show1 = true;
			this.$nextTick(() => {
				this.$refs.fieldNum.focus();
			});
		},
		// 确定，手动补仓，提交数据
		buchang() {
			this.$post2('Robot/api/index/manualBuchang', {
				bot_id: this.bot_id,
				num: this.num
			})
				.then(res => {
					// this.$toast.success('补仓成功');
					this.$toast.success(this.$t('tba.SuccessReplenish'));
					this.getBot();
				})
				.catch(e => {
					// console.log(e, "出错");
					// this.$toast.success('补仓失败');
					this.$toast.success(this.$t('tba.failReplenish'));
				});
		},
		// 确定，手动卖出，提交数据
		maichu() {
			this.$post2('Robot/api/index/manualSellOut', {
				bot_id: this.bot_id,
				num: this.num1
			})
				.then(res => {
					// this.$toast.success('补仓成功');
					this.$toast.success(this.$t('tba.SuccessSellout'));
					this.getBot();
				})
				.catch(e => {
					// console.log(e, "出错");
					// this.$toast.success('补仓失败');
					this.$toast.success(this.$t('tba.failSellout'));
				});
		},

		// 清仓卖出
		sellOut() {
			if (this.dataInfo.state == 1) {
				// this.$toast.fail('请先停止机器人');
				this.$toast.fail(this.$t('tba.stopRobotFirst'));
				return;
			}
			let toast = this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				// message: '处理中...'
				message: this.$t('tba.Processing')
			});
			this.$post2('Robot/api/index/sellOut', {
				bot_id: this.bot_id
			})
				.then(res => {
					// this.$toast.success('清仓成功');
					toast.clear();
					this.$toast.success(this.$t('tba.SuccessClearance'));
					this.getBot();
				})
				.catch(e => {
					// console.log(e, "出错");
					// this.$toast.success('清仓失败');
					toast.clear();
					this.$toast.success(this.$t('tba.failClearance'));
				});
		},
		// 清理挂单
		qingGua() {
			if (this.dataInfo.state == 1) {
				// this.$toast.fail('请先停止机器人');
				this.$toast.fail(this.$t('tba.stopRobotFirst'));
				return;
			}
			let toast = this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				// message: '处理中...'
				message: this.$t('tba.Processing')
			});
			this.$post2('Robot/api/index/clearHangingOrder', {
				bot_id: this.bot_id
			})
				.then(res => {
					toast.clear();
					this.$toast.success(this.$t('tba.Successqinggua'));
					this.getBot();
				})
				.catch(e => {
					toast.clear();
					this.$toast.success(this.$t('tba.failqinggua'));
				});
		},
		// 策略设置
		robotParamSetup() {
			this.$router.push({
				path: 'robotSetup',
				query: {
					bot_id: this.dataInfo.bot_id,
					exchange: this.dataInfo.web,
					type: this.dataInfo.type,
					fangshi: this.fangshi
					// lastpath: this.$route.fullPath,
				}
			});
		},

		// 停止/启动-设置按钮
		toggleBot() {
			let toast = this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				// message: '处理中...'
				message: this.$t('tba.Processing')
			});
			this.$post2('Robot/api/index/toggleBot', {
				bot_id: this.bot_id,
				state: this.state
			})
				.then(res => {
					if (this.state == 1) {
						this.$toast.success(this.$t('tba.qidongsuccess'));
					} else if (this.state == 0) {
						this.$toast.success(this.$t('tba.StopSuccess'));
					}
					this.getBot();
					toast.clear();
				})
				.catch(e => {
					toast.clear();
					this.$toast.fail(e);
				});
		},

		// 初始化-获取详情信息
		getBot() {
			let toast = this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				// message: '加载中...'
				message: this.$t('tba.Loading')
			});
			this.$post2('Robot/api/index/getBotV2', {
				bot_id: this.bot_id,
				noToast: true
			})
				.then(res => {
					this.dataInfo = res;
					this.strategy = res.param_arr;
					if (!this.dataInfo.total_profit) {
						this.dataInfo.total_profit = 0;
					}
					if (res.type == 'wangge') {
						if (!this.strategy.v8) {
							this.strategy.v8 = 2;
						}
						if (!this.strategy.v9) {
							this.strategy.v9 = 0;
						}
					}
					if (res.type == 'wangge2') {
						if (!this.strategy.v9) {
							this.strategy.v9 = 0;
						}
					}
					if (res.type == 'wave') {
						if (!this.strategy.v7) {
							this.strategy.v7 = 0;
						}
					}
					if (res.type == 'wangge_m') {
						if (!this.strategy.v16) {
							this.strategy.v16 = 0;
						}
					}
					if (res.type == 'wave_f') {
						if (!this.strategy.v9) {
							this.strategy.v9 = 0;
						}
					}
					if (res.type == 'wave_f_long') {
						if (!this.strategy.v8) {
							this.strategy.v8 = 0;
						}
					}
					if (res.type == 'wave_f_short') {
						if (!this.strategy.v8) {
							this.strategy.v8 = 0;
						}
					}
					if (res.type == 'wangge_f') {
						if (!this.strategy.v8) {
							this.strategy.v8 = 2;
						}
						if (!this.strategy.v11) {
							this.strategy.v11 = 0;
						}
					}
					if (res.type == 'wave_f_coin') {
						if (!this.strategy.v9) {
							this.strategy.v9 = 0;
						}
					}
					toast.clear();
				})
				.catch(e => {
					// console.log(e, "出错");
					// this.$toast.fail('获取机器人失败');
					this.$toast.fail(this.$t('tba.FailedAcquireRobot'));
					toast.clear();
				});
		},
		yes() {
			this.sellOut()
		},
		yes3() {
			this.qingGua()
		}
	}
};
</script>

<style lang="less" scoped>
.circularStrategy {
	.content {
		padding: 0.25rem;

		.csTitle {
			color: #373737;
			font-size: 0.32rem;
			// font-family: Bahnschrift;
			font-weight: bold;
			height: 0.63rem;
			display: flex;
			align-items: center;
			padding-bottom: 0.25rem;
			margin-bottom: 0.2rem;

			span {
				color: #90c4ff;
				font-size: 0.2rem;
				padding: 0 0.1rem;
				line-height: 0.36rem;
				background: #f1f7ff;
				border-radius: 0.04rem;
				display: inline-block;
				margin-left: 0.1rem;
				&.gray {
					color: #9b9b9b;
					background: #f5f5f5;
				}
				&.gray1 {
					color: #de5841;
					background: rgba(222, 88, 65, 0.09);
				}
				&.gray2 {
					color: #4daa90;
					background: rgba(77, 170, 144, 0.09);
				}
			}
		}
	}

	.strategy {
		.strategyItem {
			padding: 0.1rem 0.2rem;
			margin-bottom: 0.1rem;
			&:last-child {
				padding-bottom: 0;
				margin-bottom: 0.34rem;
			}
		}

		.xian {
			padding: 0.01rem 0.2rem;
			border-top: 0.01rem solid #f5f5f5;
		}

		.strategyItem1 {
			padding: 0.28rem 0.2rem 0 0.2rem;
			// margin-bottom: .1rem;
			&:last-child {
			}
		}

		.span1 {
			font-size: 0.24rem;
			color: #666666;
			margin-right: 0.12rem;
		}

		.span2 {
			font-size: 0.28rem;
			font-family: Bahnschrift;
			font-weight: 600;
			color: #373737;
		}
	}

	.action {
		// padding-bottom: 1rem;

		.actionItem {
			// margin-top: 0.55rem;
			padding-top: 0.1rem;
			text-align: center;

			img {
				height: 0.6rem;
				display: block;
				margin: auto;
			}

			p {
				margin-top: 0.12rem;
				font-size: 0.24rem;
				color: #666666;
			}
		}

		.actionButton {
			height: 0.79rem;
			width: 69%;
			margin: 1rem auto 0;
			background: #ff4b04;
			border-radius: 100px;
			font-size: 0.31rem;
			font-weight: bold;
			color: #ffffff;
			line-height: 0.78rem;
			text-align: center;
		}
	}
}

// ------------20200412[start]----------------
.err_msg {
	width: 100%;
	height: 0.4rem;
	line-height: 0.4rem;
	background-color: rgba(245, 70, 93, 0.1);
	color: #f5465d;
	font-size: 0.24rem;
	text-align: center;
}
.msg_list {
	padding: 0.25rem 0.3rem;
}
.layoutBox {
	min-height: calc(100vh - 1.76rem);
}
.head_list {
	display: flex;
	justify-content: space-between;
	.numItem {
		width: 33.3%;
		text-align: center;
		margin-bottom: 0.5rem;
		p {
			color: #666666;
			font-size: 0.24rem;
			margin-bottom: 0.1rem;
		}

		span {
			color: #373737;
			font-family: Bahnschrift;
			font-size: 0.28rem;
		}
	}
	.numItem1 {
		width: 33.3%;
		text-align: center;
		p {
			color: #666666;
			font-size: 0.24rem;
			margin-bottom: 0.1rem;
		}

		span {
			color: #373737;
			font-family: Bahnschrift;
			font-size: 0.28rem;
		}
	}
}
.csTitle1 {
	margin-top: 0.25rem;
	border-top: 0.01rem solid rgba(211, 217, 222, 0.5);
	padding: 0.2rem 0 0 0;
	color: #333333;
	font-size: 0.28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
	span {
		font-weight: bold;
	}
}
// ------------20200412[end]----------------
</style>
